<template>
  <div class="mb-20">
    <div class="border border-solid border-blue-200">
      <div class="flex">
        <div
          class="duration-300"
          :class="{ 'w-full': !isOpen, 'w-1/2': isOpen }"
        >
          <datatable
            :url="table.url"
            :ajax="true"
            :ajax-pagination="true"
            :exportable="false"
            :index="true"
            :columns="table.columns"
            :loading="table.loading"
            :on-click="selectRow"
            :query="searchQuery"
            :page-details="true"
            ref="table"
          />
        </div>
        <div class="w-1/2 px-8 py-10 border-l border-blue-200" v-if="isOpen">
          <div class="overflow-y-auto">
            <div class="animated animation-delay-300 fadeIn">
              <transition name="fade" mode="out-in">
                <div>
                  <datatable
                    :exportable="false"
                    :index="true"
                    :columns="walletHistory.columns"
                    :data="walletHistory.data"
                    :loading="walletHistory.loading"
                    :page-details="true"
                    :fillable="false"
                    ref="walletTable"
                  />
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    query: {
      type: String,
      default: ""
    },
    searchField: {
      type: [HTMLInputElement],
      default: null
    },
    staff: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: null,
      searchQuery: "",
      table: this.$options.resource([], {
        url: `${this.$baseurl}/admin/personal/repayments/wallet/${this.staff?.id}`,
        columns: [
          {
            name: "repayment_id",
            th: "Repayment ID"
          },
          {
            name: "total_paid",
            th: "Fraction Paid"
          },
          {
            name: "repayment_amount",
            th: "Repayment Amount",
            render: wallet => {
              return this.$options.filters.currency(
                wallet?.repayment_amount || 0
              );
            }
          },
          {
            name: "created_at",
            th: "Date Created",
            render: wallet => {
              return this.$moment(wallet?.created_at).format("YYYY-MM-DD");
            }
          }
        ]
      }),
      repaymentWallet: this.$options.resource([], {
        loading: false
      }),
      walletHistory: this.$options.resource([], {
        url: `${this.$baseurl}/admin/personal/repayments/wallet/${this.selectedPayment?.id}/history`,
        loading: false,
        columns: [
          {
            name: "previous_total_paid",
            th: "Previous Balance",
            render: history => {
              return this.$options.filters.currency(
                history?.previous_total_paid || 0
              );
            }
          },
          {
            name: "current_total_paid",
            th: "Current Balance",
            render: history => {
              return this.$options.filters.currency(
                history?.current_total_paid || 0
              );
            }
          },
          {
            name: "amount",
            th: "Amount Paid",
            render: history => {
              return this.$options.filters.currency(
                history?.amount || 0
              );
            }
          },
          {
            name: "fee",
            th: "Fee",
            render: history => {
              return this.$options.filters.currency(
                history?.fee || 0
              );
            }
          },
          {
            name: "created_at",
            th: "Date Created",
            render: history => {
              return this.$moment(history?.created_at).format("YYYY-MM-DD");
            }
          }
        ]
      }),
      selectedPayment: null
    };
  },
  computed: {
    isOpen() {
      return !!this.selectedPayment;
    }
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    selectRow(selectedPayment) {
      this.selectedPayment =
        this.selectedPayment?.id === selectedPayment?.id ? null : selectedPayment;
      this.$refs.table.renderData();
      this.$refs.walletTable?.renderData();
      if (this.selectedPayment) {
        this.getRepaymentWalletHistory();
      }
    },
    tableRowClassName(row) {
      return row.id === this.selectedPayment?.id ? "selected" : "";
    },
    async getRepaymentWalletHistory() {
      this.repaymentWallet.loading = true;
      await this.sendRequest(
        "admin.repayments.getRepaymentWalletHistory",
        this.selectedPayment?.id,
        {
          success: response => {
            this.walletHistory.data = response.data.datatable.data;
          },
          error: error => {
            this.repaymentWallet.error = error;
          }
        }
      );
      this.repaymentWallet.loading = false;
    }
  }
};
</script>
